import { Modal } from "react-bootstrap";
import mail from "../../assests/images/mail-logo.png";

const ModalBox = ({ showModal, closeModal, saveEmailInDataBase, handleSubmit, interest, handleDropdownChange, message, handleMessageChange }) => {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Body>
        {saveEmailInDataBase ?
          (
            <div>
              <h2 className="dropdown-heading">Get in touch</h2>
              <p className="dropdown-list">I'm interested in:</p>
              <select className="modal-dropdown"
                value={interest}
                onChange={handleDropdownChange}>
                <option className="dropdown-option" value="Investing">
                  Investing
                </option>
                <option className="dropdown-option" value="Minimis Flow AR">
                  Minimis Flow AR
                </option>
                <option
                  className="dropdown-option"
                  value="Minimis Phone (Android)"
                >
                  Minimis Phone (Android)
                </option>
                <option className="dropdown-option" value="Minimis Phone (iOS)">
                  Minimis Phone (iOS)
                </option>
              </select>
               <div className="dropdown-optional-text">
                <textarea placeholder="Your message (Optional)..."
                  value={message}
                  onChange={handleMessageChange}></textarea>
              </div>
              <button onClick={handleSubmit}>Submit</button>
            </div>
          )
          :
          (
            <div className="thank-you-content">
              <h2 className="thank-you-heading">Thank You!</h2>
              <div className="thank-you-touch" style={{ marginBottom: "8px" }}>
                We will be in touch soon.
              </div>
              <div className="thank-you-img">
                <img src={mail} alt="Mail logo" />
              </div>
              <button onClick={closeModal}>Close</button>
            </div>
          )
        }
      </Modal.Body>
    </Modal>
  );
};

export default ModalBox;
